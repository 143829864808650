.contacts {
  background: url("../img/bg-contacts.jpg") no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-top: 97px;
  color: #fff;

  &__title {
    @include section-title(#fff); }

  &-list {
    max-width: 620px;
    margin: 125px auto 55px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media #{$tablet} {
      justify-content: space-around;
      margin-top: 50px; }

    &-item {
      display: flex;
      justify-content: space-around;
      font-size: 20.32px;
      width: 290px;
      align-items: center;
      @media #{$tablet} {
        margin-bottom: 20px; } } }

  &-form {

    &-fields {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      width: 915px;
      margin: auto;
      @media #{$tablet} {
        justify-content: space-around;
        width: auto;
        height: auto; } }

    &-inputs {
      width: 100%;
      max-width: 425px;
      @media #{$tablet} {
        width: 290px; }

      @media  #{$phone} and (orientation: landscape) {
        width: 320px; } }

    &__item {
      width: 100%;
      max-width: 425px;
      height: 50px;
      margin-bottom: 30px;
      @media #{$tablet} {
        width: 290px;
        margin-bottom: 50px; }

      @media  #{$phone} and (orientation: landscape) {
        width: 320px; }

      &.message {
        height: 130px;
        & textarea {
          padding-top: 20px; } }

      &>* {
        display: block;
        width: 100%;
        height: 100%;
        padding-left: 17px;
        border: none;
        resize: none {
          -webkit-appearance: none; }
        box-shadow: none;
        -webkit-box-shadow: none;
        -webkit-border-radius: 0;
        -moz-border-radius: 0;
        border-radius: 0;
        -webkit-appearance: none;
        @include text(); } }

    &__submit {
      display: block;
      width: 425px;
      margin: auto;
      height: 50px;
      background: #fff;
      border: 1px solid #fff;
      cursor: pointer;
      margin-top: 60px;
      @include text(16.54px, 50px, center);
      text-transform: uppercase;
      color: $main-color;
      -webkit-appearance: none;
      box-shadow: none;
      -webkit-box-shadow: none;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
      -webkit-appearance: none;
      @media #{$tablet} {
        width: 290px; }

      &:hover {
        background: $main-color;
        color: #fff; } }


    &_w {
      border-top: 1px solid #fff;
      padding-top: 55px;
      margin-top: 54px;
      padding-bottom: 122px; } } }


