.administration {
  margin-top: -50px;
  overflow: hidden;
  padding-top:   115px;
  &__title {
    @include section-title($main-color); }

  &__desc {
    @include text();
    text-align: center;
    margin-top:  100px;
    margin-bottom:  125px; } }

.department {
  padding-bottom: 75px;

  &__title {
    font-size: 20.32px;
    color: $main-color;
    margin: 0 auto;
    text-align: center;
    line-height: 1.2em;
    position: relative;
    text-transform: uppercase;
    font-weight: 700;

    @media #{$phone} {
      & span::before, & span::after {
        display: none; } }

    & span {
      position: relative;
      &::before, &::after {
        content: '';
        position: absolute;
        width: 1000px;
        height: 1px;
        top: 17px;
        left: -1030px;
        background: #cca675; }

      &::after {
        left: auto;
        right: -1030px; } } }

  &-list {
    margin-top: 110px;
    display: flex;
    flex-wrap: wrap;
    justify-content:  space-between;
    @media #{$tablet} {
      justify-content: space-around; }

    &-item {
      width: 552px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-bottom: 75px;
      @media #{$tablet} {
        justify-content: space-around; }

      &.margined {
        margin: auto;
        margin-bottom: 75px; }

      &-content {
        max-width:  395px;
        @include text();
        @media #{$tablet} {
          margin-top: 20px; }

        &__title {
          font-weight: 600;
          margin-bottom: 25px; }

        &__divider {
          width: 100%;
          height: 1px;
          background: #cca675;
          margin-bottom: 30px;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            top: 1px;
            left: 0;
            width: 7px;
            height: 7px;
            background: #cca675; } } } } } }









