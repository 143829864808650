.header {
  background: url('../img/bg-header.jpg') no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  padding-bottom: 107px;

  &.sticky {
    padding-bottom: 14px;
    border-bottom: 1px solid #fff;
    background: $main-color;
    position: sticky;
    top: 0;
    z-index: 999999; }

  @media #{$phone} {
    background: #077bfd; }

  &__burger {
    margin: 30px auto;
    width: 30px;
    height: 30px;
    cursor: pointer;
    position: relative;
    display: none;
    @media #{$phone} {
      display: block; }
    & span {
      display: block;
      height: 4px;
      width: 100%;
      background: #fff;
      transition: all, 0.5s;
      &:not(:first-child) {
        margin-top: 2px; } }

    &.active {
      & span:nth-child(2) {
        display: none; }

      & span:first-child {
        transform: rotate(45deg);
        position: absolute;
        left: 0;
        top: 10px; }

      & span:last-child {
        transform: rotate(-45deg);
        position: absolute;
        right: 0;
        top: 9px; } } }



  &-top {
    display: flex;
    position: sticky;
    top: 0;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 85px;
    align-items: center;
    color: #fff;
    @media #{$tablet} {
      justify-content: space-around; }

    &-nav {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      height: 43px;
      line-height:  1em;
      align-self: flex-start;
      align-items: flex-end;
      @media #{$tablet} {
        margin-bottom: 30px; }

      @media #{$phone} {
        display: none;
        flex-direction: column;
        background: #fff;
        width: 100%;
        color: $main-color;
        align-items: center;
        padding: 15px;
        height: auto; }

      @media #{$phone} and (orientation: landscape) {
        position: absolute;
        top: 100px;

        .header-top-nav__item {
          margin-bottom: 20px;
          &::before {
            background: $main-color; }

          &::after {
            display: none; } } }

      &__item {
        display: block;
        padding-left: 15px;
        padding-right: 12px;
        text-transform: uppercase;
        position: relative;
        font-size: 12.7px;

        &::before {
          content: '';
          position: absolute;
          bottom: -8px;
          left: 0;
          height: 2px;
          width: 0;
          background: #ffffff;
          transition: all, 0.5s;
          @media #{$tablet} {
            display: none; } }

        &:hover::before {
          width: 100%;
          transition: all, 1s; }

        &:not(:last-child)::after {
          content: '';
          position: absolute;
          bottom: 0;
          right: 0;
          height: 43px;
          width: 1px;
          background: #79b5ff;
          @media #{$phone} {
            display: none; } } } }

    &-tel {
      display: flex;
      justify-content: space-between;
      width: 188px;
      height: 35px;
      align-items: center;
      font-size:  20.32px; } }

  &-main {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    color: #fff;

    &__title {
      @include text(80px, 99px, center, 100, 'title-ff');
      margin-top: 40px;
      @media #{$tablet} {
        font-size: 45px; }
      @media #{$phone} {
        font-size: 32px; }

      &.small {
        font-size: 30px;
        margin-top: 20px; } }

    &__description {
      @include text(16.5px, 25.4px, center);
      margin-top: 50px;
      margin-bottom: 40px;
      max-width: 890px;
      text-align: center;

      & li:last-child {
        margin-top: 60px; } }


    &-download {
      margin-top: 150px;
      width: 90%;
      max-width: 424px;
      @include text();
      text-align: center;


      &__button {
        display: block;
        text-align: center;
        color: $main-color;
        text-transform: uppercase;
        padding: 20px;
        margin-top:  28px;
        background: #fff;
        border: 1px solid #fff;
        transition: all, 0.5s;
        &:hover {
          color: #ffffff;
          background: $main-color;
          transition: all, 0.5s; } } } } }






