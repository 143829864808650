body {
  font-family: 'Open Sans', sans-serif;
  color: #303030; }

.container {
  margin: 0 auto;
  width: 1170px;

  @media #{$tablet} {
    width: 740px;
    padding: 0 15px; }

  @media #{$phone} {
    width: 100%;
    max-width: 100%;
    padding: 0 15px; }

  @media #{$desktop-small} {
    width: 980px; } }

.footer {
  padding: 35px 0 23px;
  text-align: center; }

.modal {
  display: none;
  background: rgba(0,0,0,.7);
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 9;
  cursor: pointer;
  &__content {
    z-index: 10;
    position: fixed;
    border: 1px solid #bdd7e5;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    background: #fff;
    color: #666;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    max-height: 90%;
    max-width: 90%;
    & .text {
      padding: 20px 50px; } }


  & .close-modal {
    position: absolute;
    right: 0;
    top: 0; } }

.to-header {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 70px;
  height: 70px;
  background: rgba(0, 0, 0, 0.5);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  cursor: pointer;
  display: flex;

  @media #{$tablet} {
    width: 30px;
    height: 30px;
    bottom: 40px; }

  .icon-up {
    margin: auto; } }

@media #{$tablet} {
  section, header {
    overflow: hidden; } }



