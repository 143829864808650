@mixin text($fz: 16.5px, $lh: 20.54px, $ta: left, $fw: 400, $ff: 'Open Sans') {
  font-size: $fz;
  line-height: $lh;
  text-align: $ta;
  font-weight: $fw;
  font-family: $ff; }

@mixin section-title($color) {
  font-size: 45.83px;
  color: $color;
  margin: 0 auto;
  text-align: center;
  line-height: 1.2em;
  font-weight: bold;
  text-transform: uppercase;

  @media #{$tablet} {
    font-size: 32px; }

  & span {
    position: relative;
    &::before, &::after {
      content: '';
      position: absolute;
      width: 1000px;
      height: 1px;
      top: 36px;
      left: -1030px;
      background: $color;

      @media #{$tablet} {
        top: 24px; } }

    &::after {
      left: auto;
      right: -1030px; } } }




