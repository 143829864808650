.portfolio {
  overflow: hidden;
  padding: 110px 0 60px;

  &__title {
    @include section-title($main-color);
    margin-bottom:  110px; }

  &-main {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @media #{$tablet} {
      justify-content: space-around; }

    &-list {
      max-width: 552px; } }

  &-item {
    margin-bottom:  40px;
    @include text();

    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap; }

    &__logo {
      margin-bottom: 25px; }

    &__link {
      display: block;
      color: $main-color;
      margin-bottom: 20px;
      font-weight: 700;
      &:hover {
        text-decoration: underline; } }

    &__name {
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom:  30px; }

    &__divider {
      width: 100%;
      height: 1px;
      background: #cca675;
      margin-top: 30px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 1px;
        left: 0;
        width: 7px;
        height: 7px;
        background: #cca675; } }

    &-benefits {
      &__item {
        padding-left:  25px;
        position: relative;
        &::before {
          content: '';
          position: absolute;
          left: 0;
          top:  10px;
          width: 7px;
          height: 7px;
          background: $main-color; } } } } }



