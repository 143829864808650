$desktop:             'only screen and (min-width: 1200)';
$tablet:              'only screen and (max-width: 1024px)';
$desktop-small:        'only screen and  (max-width: 1200px)';
$tablet-portrait:     'only screen and (min-width: 768px) and (max-width: 1024px) and (orientation: portrait)';
$phone:               'only screen and (max-width: 767px)';
$phone-s:   'only screen and (max-width: 544px)';

$main-color: #0384fa;







