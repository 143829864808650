.about {
  overflow: hidden;
  padding-top: 100px;
  @include text();
  text-align: center;

  &__title {
    @include section-title($main-color); }

  &__description {
    margin-top: 60px;
    color: #303030;

    span {
      color: $main-color; } }

  &-list {
    margin-top: 65px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include text(16.51px, 20.54px, center, 600);
    @media #{$tablet} {
      justify-content: space-around; }

    &-item {
      max-width: 135px;
      @media #{$tablet} {
        margin-bottom: 30px; }

      &__img {
        margin: 0 auto 40px; } } }

  &__text {
    margin: 75px auto 90px; } }

.description {
  background: url("../img/bg-about.png") no-repeat center top;
  padding-top:  50px;
  font-size: 20px;

  .about__title {
    margin: 0 0 30px; }

  &.top {
    position: relative;
    top: -50px; }

  &__title .container {
    height: 80px;
    display: flex;
    & img {
      display: block;
      margin: auto;
      width: 100%;
      height: 33px; } }

  .how-main {
    display: flex;
    justify-content: space-around;
    margin-top: 0;

    &-list {
      margin: 30px 0;
      text-align: left;

      li {
        margin-top: 15px;

        &::before {
          background: $main-color; } } } }

  &-text {
    background: url("../img/bg-description.jpg") no-repeat center;
    -webkit-background-size: cover;
    background-size: cover;

    @include text();
    text-align: center;
    padding:  110px 0;

    p {
      margin-bottom: 40px;
      max-width: 1100px;
      margin-left: auto;
      margin-right: auto; }

    &__inject {
      margin: 30px auto 60px;

      span {
        color: $main-color;
        font-size: 17.78px;
        line-height: 30.48px;
        text-transform: uppercase; } } } }




