.how {
  background: url('../img/bg-how.jpg') no-repeat center;
  -webkit-background-size: cover;
  background-size: cover;
  overflow: hidden;
  padding-top:  100px;
  padding-bottom: 100px;
  color: #fff;

  @media #{$tablet} {
    padding-bottom: 150px; }

  @media #{$phone} {
    background: #077bfd; }

  &__title {
    @include section-title(#fff);
    margin-bottom:  100px; }

  &__desc {
    @include text();
    text-align: center; }

  &-main {
    margin-top:  100px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media #{$tablet} {
      justify-content: space-around; }

    @include text();

    &-list {
      width: 47%;
      line-height:  25.4px;

      @media #{$phone} {
        width: 100%; }

      li {
        position: relative;
        padding-left:  25px;
        &::before {
          content: '';
          position: absolute;
          top: 8px;
          left: 0;
          width: 8px;
          height: 7px;
          background: #fff; } } } } }

