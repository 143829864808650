$color_white: #fff;
$color_prime: #0384fa;
$color_grey: #e2e2e2;
$color_grey_dark: #a2a2a2;

.blog-page .header {
  padding-bottom: 0;
}
.blog-page .container-fluid {
  padding-top: 60px;
}
.loader_w {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader{
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;
}

#loader-1:before, #loader-1:after{
  content: "";
  position: absolute;
  top: -10px;
  left: -10px;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 10px solid transparent;
  border-top-color: #3498db;
}

#loader-1:before{
  z-index: 100;
  animation: spin 1s infinite;
}

#loader-1:after{
  border: 10px solid #ccc;
}

@keyframes spin{
  0%{
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100%{
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}


.blog-list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.pagination {
  display: flex;
  justify-content: center;
  margin: 30px auto 60px;

  &__item {
    width: 35px;
    height: 35px;
    background: $color_prime;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    color: $color_white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin: 0 5px;

    &.current {
      background: $color_white;
      color: $color_prime;
      border: 1px solid $color_prime;
    }
  }
}

.blog-card {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  box-shadow: 0 3px 7px -1px rgba(#000, .1);
  margin-bottom: 1.6%;
  background: $color_white;
  line-height: 1.4;
  font-family: sans-serif;
  border-radius: 5px;
  overflow: hidden;
  z-index: 0;
  a {
    color: inherit;
    &:hover {
      color: $color_prime;
  }
}
  &:hover {
    .photo {
      transform: scale(1.3) rotate(3deg);
  }
}
  .meta {
    position: relative;
    z-index: 0;
    height: 200px;
}
  .photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-size: cover;
    background-position: center;
    transition: transform .2s;
}
  .details,
  .details ul {
    margin: auto;
    padding: 0;
    list-style: none;
}

  .details {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100%;
    margin: auto;
    transition: left .2s;
    background: rgba(#000, .6);
    color: $color_white;
    padding: 10px;
    width: 100%;
    font-size: .9rem;
    a {
      text-decoration: dotted underline
  }
    ul li {
      display: inline-block;
  }
    .author:before {
      font-family: FontAwesome;
      margin-right: 10px;
      content: "\f007";
  }

    .date:before {
      font-family: FontAwesome;
      margin-right: 10px;
      content: "\f133";
  }

    .tags {
      ul:before {
        font-family: FontAwesome;
        content: "\f02b";
        margin-right: 10px;
    }
      li {
        margin-right: 2px;
        &:first-child {
          margin-left: -4px;
      }
    }
  }
}
  .description {
    padding: 1rem;
    background: $color_white;
    position: relative;
    z-index: 1;
    h1,
    h2,
    h3 {
      font-family: Poppins, sans-serif;
  }
    h1, h2 {
      line-height: 1;
      margin: 0;
      font-size: 1.7rem;
  }
    h3 {
      font-size: 1rem;
      font-weight: 300;
      text-transform: uppercase;
      color: $color_grey_dark;
      margin-top: 5px;
  }
    .read-more {
      text-align: right;
      span {
        color: $color_prime;
        display: inline-block;
        position: relative;
        cursor: pointer;
        &:after {
          content: "\f061";
          font-family: FontAwesome;
          margin-left: -10px;
          opacity: 0;
          vertical-align: middle;
          transition: margin .3s, opacity .3s;
      }

        &:hover:after {
          margin-left: 5px;
          opacity: 1;
      }
    }
  }
}
  p {
    position: relative;
    margin: 1rem 0 0;
    &:first-of-type {
      margin-top: 1.25rem;
      &:before {
        content: "";
        position: absolute;
        height: 5px;
        background: $color_prime;
        width: 35px;
        top: -0.75rem;
        border-radius: 3px;
    }
  }
}
  &:hover {
    .details {
      left: 0%;
  }
}


@media (min-width: 640px) {
  flex-direction: row;
  max-width: 560px;
  .meta {
    flex-basis: 40%;
    height: auto;
}
  .description {
    flex-basis: 60%;
    &:before {
      transform: skewX(-3deg);
      content: "";
      background: #fff;
      width: 30px;
      position: absolute;
      left: -10px;
      top: 0;
      bottom: 0;
      z-index: -1;
  }
}
  &.alt {
    flex-direction: row-reverse;
    .description {
      &:before {
        left: inherit;
        right: -10px;
        transform: skew(3deg)
    }
  }
    .details {
      padding-left: 25px;
  }
}
}
}

.blog-page {
  background: #f4f4f1;
}

.single-article {
  background: #f4f4f1;
  font-family: 'Open Sans', sans-serif;
  position: relative;
  padding: 0 30px;
  margin-bottom: 30px;

  article {
    position: relative;
    width: 80%;
    margin: 80px auto 0;
    background: #ffffff;
    padding: 80px 80px 70px;
    border-right: 1px solid #f4f4f1;
    border-bottom: 1px solid #f4f4f1;
  }

  article h2 {
    color: $color_prime;
    margin: 30px 0;
    font-size: 24px;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  article p {
    margin-top: 18px;
    color: #404649;
    font-weight: 400;
  }

  /* The author card */


  .author {
    position: absolute;
    width: 300px;
    height: 300px;
    background: #ffffff;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    border-left: 1px solid #f4f4f1;
    border-bottom: 1px solid #f4f4f1;
    top: 50px;
    right: 10%;
    z-index: 2;
  }

  .watch-other {
    display: block;
    cursor: pointer;
    color: $color_prime;
    font-size: 24px;
  }

  .author .name {
    display: block;
    text-align: center;
    color: #507da5;
    text-decoration: none;
    font-weight: 600;
  }

  .author img.circle {
    border-radius: 100%;
  }

  .author p {
    text-align: left;
    font-size: 12px;
    margin-top: 18px;
    color: #404649;
    font-weight: 400;
  }

}